<template>
  <div>
    <el-row ref="search_form">
      <el-col :span="24">
        <div class="search-box">
          <el-form :inline="true" size="medium" :model="searchForm">
            <el-form-item label="">
              <el-input
                v-model="searchForm.userName"
                size="mini"
                placeholder="请输入姓名"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-select
                v-model="searchForm.plateId"
                size="mini"
                placeholder="请选择会议"
                clearable
              >
                <el-option
                  v-for="item in plateList"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                ></el-option>
              </el-select>
              <el-select
                v-model="searchForm.scanStatus"
                size="mini"
                placeholder="scan状态"
                clearable
              >
                <el-option :key="0" label="成功记录" :value="0"></el-option>
                <el-option
                  :key="1"
                  label="失败记录(陌生人)"
                  :value="1"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>

          <el-form :inline="true" size="medium" :model="searchForm">
            <el-form-item>
              <el-button size="mini" type="primary" @click="search"
                >查询</el-button
              >
            </el-form-item>
            <el-form-item>
              <el-button size="mini" type="primary" @click="clear"
                >清空</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </el-col>
    </el-row>

    <el-table
      :data="tableData"
      ref="elTable"
      :header-cell-style="{ backgroundColor: '#f4f4f5', textAlign: 'center' }"
      :height="tableHeight"
      border
      style="width: 100%"
    >
      <el-table-column type="selection" align="center" width="55">
      </el-table-column>
      <el-table-column
        fixed
        align="center"
        prop="id"
        label="序号"
        type="index"
        width="80"
      >
      </el-table-column>
      <el-table-column prop="userId" label="核销员fid" min-width="80">
      </el-table-column>
      <el-table-column prop="frontUserId" label="用户id" min-width="80">
      </el-table-column>
      <el-table-column prop="createTime" label="核销时间" min-width="150">
        <template slot-scope="scope">
          {{ fmtDate2(scope.row.createTime) }}
        </template>
      </el-table-column>
      <el-table-column prop="sj" label="事件" min-width="80"> </el-table-column>
      <el-table-column prop="linkId" label="关联id" min-width="80">
      </el-table-column>
      <el-table-column prop="linkType" label="会议酒店餐饮" min-width="80"
        >judgeDate
        <template slot-scope="scope">
          {{ judgeDate(scope.row.linkType) }}
        </template>
      </el-table-column>
      <el-table-column prop="plateTitle" label="会议" min-width="80">
      </el-table-column>
      <el-table-column prop="scanStatus" label="识别状态" min-width="80">
        <template slot-scope="scope">
          {{ judgeDate1(scope.row.scanStatus) }}
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="faceData"
        label="人脸图片"
        min-width="60">
        <template slot-scope="scope">
          <el-image  :src="scope.row.faceData" width="40" :preview-src-list="[scope.row.faceData]" />
        </template>

      </el-table-column> -->
      <el-table-column prop="gender" label="性别" min-width="80">
        <template slot-scope="scope">
          {{ judgeDate2(scope.row.gender) }}
        </template>
      </el-table-column>
      <el-table-column prop="username" label="姓名" min-width="80">
      </el-table-column>
      <el-table-column prop="recogTime" label="识别时间" min-width="150">
      </el-table-column>
    </el-table>
    <el-row>
      <el-col :span="24" class="pagination-box">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next"
          :page-size="pageSize"
          :page-sizes="[20, 50, 100]"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        >
        </el-pagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import request from "@/common/utils/request.js";
import util from "@/common/utils/util.js";
import { getMyParticipateList } from "@/api/participate";
import { getScanLogList } from "@/api/scan";
import { getPlateList, getPlateListWithGroup } from "../../api/plate";

export default {
  name: "scanLog",
  data() {
    return {
      total: 0,
      pageSize: 20,
      currentPage: 1,
      tableData: [],
      tableHeight: 200,
      editFormVisible: false,
      loading: false,
      loadingDiv: null,
      searchForm: {
        userName: "",
        plateId: "",
        scanStatus: "",
      },

      plateList: [],
    };
  },
  watch: {},
  mounted() {
    this.init();

    this.getDataList(this.currentPage);

    let offsetHeight = this.$refs.search_form.$el.offsetHeight;
    if (!offsetHeight) {
      offsetHeight = 0;
    }

    this.$nextTick(() => {
      this.tableHeight =
        document.documentElement.clientHeight - 150 - offsetHeight;
    });
    window.onresize = () => {
      this.$nextTick(() => {
        this.tableHeight =
          document.documentElement.clientHeight - 150 - offsetHeight;
      });
    };
  },
  methods: {
    init() {
      getPlateList().then((res) => {
        if (res.code) {
          this.plateList = res.data;
        }
      });
    },

    clear() {
      for (let key in this.searchForm) {
        this.searchForm[key] = "";
      }
      this.getDataList(1);
    },
    judgeDate(d) {
      // yyyy-MM-dd hh:mm:ss
      if (d == 1) {
        return "会议";
      } else if (d == 2) {
        return "酒店";
      } else if (d == 3) {
        return "餐饮";
      } else if (d == 4) {
        return "展映";
      }
      return "展览";
    },
    judgeDate1(d) {
      // yyyy-MM-dd hh:mm:ss
      if (d == 0) {
        return "成功记录";
      }
      return "失败记录(陌生人)";
    },
    judgeDate2(d) {
      // yyyy-MM-dd hh:mm:ss
      if (d == 0) {
        return "男";
      } else if (d == 1) {
        return "女";
      }
      return "未知";
    },
    fmtDate2(d2, fmt) {
      // yyyy-MM-dd hh:mm:ss
      if (util.isEmpty(d2)) {
        return "";
      }
      return this.fmtDate(new Date(d2 * 1000), fmt);
    },
    fmtDate(d, fmt) {
      // yyyy-MM-dd hh:mm:ss
      if (!fmt) {
        fmt = "yyyy-MM-dd hh:mm:ss";
      }
      var o = {
        "M+": d.getMonth() + 1, //月份
        "d+": d.getDate(), //日
        "h+": d.getHours(), //小时
        "m+": d.getMinutes(), //分
        "s+": d.getSeconds(), //秒
        "q+": Math.floor((d.getMonth() + 3) / 3), //季度
        S: d.getMilliseconds(), //毫秒
      };
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (d.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
        }
      }
      return fmt;
    },
    getDataList(pageNum) {
      console.log("页数" + pageNum);
      const loadingDiv = util.showLoading();

      let param = {
        pageSize: this.pageSize,
        pageNum: pageNum,
      };
      console.log(this.searchForm);
      for (let key in this.searchForm) {
        console.log(this.searchForm[key]);
        param[key] = this.searchForm[key];
      }

      getScanLogList(param)
        .then((res) => {
          console.log("res:");
          console.log(res);
          if (res.code == 0) {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
            });
          } else if (res.code == 1) {
            let data = res.data.records;
            console.log(data);
            this.currentPage = res.data.pages;
            this.total = res.data.total;
            this.tableData = data;
          }
          loadingDiv.close();
        })
        .catch((res) => {
          loadingDiv.close();
        });
    },
    search() {
      this.getDataList(1);
    },
    handleCurrentChange(pageNum) {
      this.getDataList(pageNum);
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.getDataList(1);
    },
  },
};
</script>

<style scoped lang="scss">
// @import "~@/common/styles/element-variables.scss";
.search-box {
  background-color: #ffffff;
  min-height: 36px;
  line-height: 36px;
  padding-top: 2px;
  padding-bottom: 6px;
  .el-form-item {
    margin-bottom: 0px !important;
  }
  .el-form--inline {
    display: inline-block;
  }
}
.pagination-box {
  text-align: left;
  margin-top: 10px;
}
</style>
<style lang="scss">
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.el-dialog__header {
}
.el-dialog__body {
  padding-top: 10px;
  padding-bottom: 0px;
}
.el-dialog__footer {
  padding-top: 0px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-tabs--border-card {
  box-shadow: none;
}
</style>
