import request from '@/common/utils/request'
import Vue from "vue";

export function getParticipateList(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/participate/get-participate-list',
    method: 'post',
    data
  })
}
export function getxiaochengxuParticipateList(data) {//xiaochengxu
  return request({
    url: Vue.prototype.$adminApiUrl + '/participate/normal-participate-list',
    method: 'post',
    data
  })
}

export function getParticipateListtwo(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/participate/same-name-list',
    method: 'post',
    data
  })
}
export function addParticipate(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/participate/add-participate',
    method: 'post',
    data
  })
}

export function updateParticipate(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/participate/update-participate',
    method: 'post',
    data
  })
}

export function deleteParticipate(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/participate/delete-participate',
    method: 'post',
    data
  })
}

export function deleteMyParticipate(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/participate/delete-my-participate',
    method: 'post',
    data
  })
}


export function getMyParticipateList(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/participate/get-my-participate-list',
    method: 'post',
    data
  })
}

export function addMyParticipate(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/participate/add-my-participate',
    method: 'post',
    data
  })
}

export function updateMyParticipate(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/participate/update-my-participate',
    method: 'post',
    data
  })
}

export function updateDLInfo(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/participate/update-dl-info',
    method: 'post',
    data
  })
}

export function updateSortBy(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/participate/global-update-sort-by',
    method: 'post',
    data
  })
}

export function shenhe(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/participate/shenhe',
    method: 'post',
    data
  })
}

// 查询贵宾
export function getParticipateGB(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/participate/get-participate-gb',
    method: 'post',
    data
  })
}

// 查询是否存在同名嘉宾
export function getParticipateSameNameJB(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/participate/get-participate-same-name-jb',
    method: 'post',
    data
  })
}

// 还原回收站嘉宾
export function recoveryParticipate(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/participate/recovery-participate',
    method: 'post',
    data
  })
}

// 绑卡
export function bind(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/participate/bind',
    method: 'post',
    data
  })
}
