import request from '@/common/utils/request'
import Vue from "vue";

/**
 * 查询扫码事件
 */
export function getScanLogList(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/scanLog',
    method: 'post',
    data
  })
}
